import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResponseItem, ResponseItems } from '@app/shared/interfaces/base';
import { Office } from '@app/shared/interfaces/office';
import {UserCalendar} from "@app/shared/interfaces/user";

@Injectable({
  providedIn: 'root'
})
export class OfficeService {
  private readonly apiUrl = environment.apiUrl;
  private readonly url: string;

  constructor(private http: HttpClient) {
    this.url = `${this.apiUrl}/offices`;
  }

  /**
   * Get all users
   */
  getAll(filter: string = ''): Observable<Office[]> {
    return this.http.get<ResponseItems<Office>>(this.url + filter).pipe(
      map((response: ResponseItems<Office>) => response.data)
    );
  }

  getOne(id: number): Observable<ResponseItem<Office>> {
    return this.http.get<ResponseItem<Office>>(`${this.url}/${id}`);
  }

  /**
   * Create user
   *
   * @param body
   */
  create(body): Observable<ResponseItem<Office>> {
    return this.http.post<ResponseItem<Office>>(this.url, body);
  }

  /**
   * Update user
   *
   * @param body
   */
  update(body: Office): Observable<ResponseItem<Office>> {
    return this.http.put<ResponseItem<Office >>(`${this.url}/${body.id}`, body);
  }

  /**
   * Delete user
   *
   * @param id
   */
  delete(body: Office): Observable<any> {
    return this.http.delete(`${this.url}/${body.id}`);
  }

  getAllForCalendar(): Observable<Office[]> {
    return this.http.get<ResponseItems<Office>>(`${this.url}-calendar`).pipe(
        map((response: ResponseItems<Office>) => response.data)
    );
  }
}
