import * as dayjs from 'dayjs';

import { DATE_FORMAT } from '@app/app.constants';

export const convertDate = (date: Date): string => {
  if (date) {
    return dayjs(date).format(DATE_FORMAT);
  }

  return '';
};
