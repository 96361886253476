import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResponseItem, ResponseItems } from '@app/shared/interfaces/base';
import { Category } from '@app/shared/interfaces/category';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private readonly apiUrl = environment.apiUrl;
  private readonly url: string;

  constructor(private http: HttpClient) {
    this.url = `${this.apiUrl}/categories`;
  }

  /**
   * Get category
   */
  getAll(filter: string = ''): Observable<Category[]> {
    return this.http.get<ResponseItems<Category>>(this.url + filter).pipe(
      map((res: ResponseItems<Category>) => res.data)
    );
  }

  /**
   * Get categoires for event
   */
  getAllForEvent(): Observable<Category[]> {
    return this.http.get<ResponseItems<Category>>(`${this.url}-event`).pipe(
      map((response: ResponseItems<Category>) => response.data)
    );
  }

  /**
   * Get one category
   *
   * @param id
   */
  getOne(id: number): Observable<ResponseItem<Category>> {
    return this.http.get<ResponseItem<Category>>(`${this.url}/${id}`);
  }

  /**
   * Create category
   *
   * @param body
   */
  create(body): Observable<ResponseItem<Category>> {
    return this.http.post<ResponseItem<Category>>(this.url, body);
  }

  /**
   * Update category
   *
   * @param body
   */
  update(body: any): Observable<ResponseItem<Category>> {
    return this.http.put<ResponseItem<Category>>(`${this.url}/${body.id}`, body);
  }

  /**
   * Delete category
   *
   * @param id
   */
  delete(id: number): Observable<any> {
    return this.http.delete(`${this.url}/${id}`);
  }
}
