import * as dayjs from 'dayjs';
import { TransferItem } from 'ng-zorro-antd/transfer/ng-zorro-antd-transfer';

export class Utils {

  /**
   * Transform date
   *
   * @param data
   */
  static transformDate(data) {
    if (typeof data) {
      Object.keys(data).forEach(key => {
        if (data[key] instanceof Date) {
          data[key] = dayjs(data[key]).format('YYYY-MM-DD HH:mm:ss');
        }
      });
    }
    return data;
  }

  /**
   * Transform array of dates
   *
   * @param dataArr
   */
  static transformArrayDate(dataArr) {
    if (dataArr.length) {
      dataArr.forEach((data, index) => {
        Object.keys(data).forEach(key => {
          if (dataArr[index][key] instanceof Date) {
            dataArr[index][key] = dayjs(data[key]).format('YYYY-MM-DD HH:mm:ss');
          }
        });
      });
    }
    return dataArr;
  }

  /**
   * Transform date
   *
   * @param data
   */
  static transformDataForTransfer(data) {
    const transform: TransferItem[] = [];
    (data || []).forEach(d => {
      if (d && (d.name || d.first_name || d.last_name)) {
        transform.push({
          key: d.id || '',
          title: d.name || d.first_name || d.last_name || '',
          direction: 'left'
        });
      }
    });
    return transform;
  }

  /**
   * Transform date
   *
   * @param data
   */
  static transformTransfer(data) {
    const transform: TransferItem = {
      key: data.id || '',
      title: data.name || data.first_name || data.last_name || '',
      direction: 'left'
    };
    return transform;
  }

  /**
   * Add from array data to array form
   *
   * @param data
   * @param form
   */
  static addFromArrayDataToArrayForm(data, form) {
    const dataId = (data || []).map(el => el.key);
    return [ ...new Set(form.concat(dataId)) ];
  }

  /**
   * Remove from array data to array form
   *
   * @param data
   * @param form
   */
  static removeFromArrayDataToArrayForm(data, form) {
    const dataId = (data || []).map(el => el.key);
    return form.filter(el => !dataId.includes(el));
  }
}
