import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResponseItem, ResponseItems } from '@app/shared/interfaces/base';
import { Group } from '@app/shared/interfaces/group';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  private readonly apiUrl = environment.apiUrl;
  private readonly url: string;

  constructor(private http: HttpClient) {
    this.url = `${this.apiUrl}/groups`;
  }

  /**
   * Get groups
   */
  getAll(filter: string = ''): Observable<Group[]> {
    return this.http.get<ResponseItems<Group>>(this.url + filter).pipe(
      map((res: ResponseItems<Group>) => res.data)
    );
  }

  /**
   * Get groups for event
   */
  getAllForEvent(): Observable<Group[]> {
    return this.http.get<ResponseItems<Group>>(`${this.url}-event`).pipe(
      map((response: ResponseItems<Group>) => response.data)
    );
  }

  /**
   * Get one group
   *
   * @param id
   */
  getOne(id: number): Observable<ResponseItem<Group>> {
    return this.http.get<ResponseItem<Group>>(`${this.url}/${id}`);
  }

  /**
   * Create group
   *
   * @param body
   */
  create(body): Observable<ResponseItem<Group>> {
    return this.http.post<ResponseItem<Group>>(this.url, body);
  }

  /**
   * Update group
   *
   * @param body
   */
  update(body: any): Observable<ResponseItem<Group>> {
    return this.http.put<ResponseItem<Group>>(`${this.url}/${body.id}`, body);
  }

  /**
   * Delete group
   *
   * @param id
   */
  delete(id: number): Observable<any> {
    return this.http.delete(`${this.url}/${id}`);
  }
}
