import * as dayjs from 'dayjs';

import { DateTransformType } from '@app/shared/interfaces/date';

export class DateTransform {

  /**
   * Get years
   *
   * @param year
   */
  static years(year = 2015): string[] {
    const years = [];

    let dateStart = dayjs().year(year);
    const dateEnd = dayjs();

    while (dateEnd.diff(dateStart, 'year') > 0) {
      years.push(dateStart.format('YYYY'));
      dateStart = dateStart.add(1, 'year');
    }

    years.push(dateEnd.format('YYYY'));
    return years;
  }

  /**
   * Month
   *
   */
  static month(date?: Date, format = 'MMMM'): string {
    date = date || dayjs().toDate();
    return dayjs(date).format(format);
  }

  /**
   * Date
   *
   */
  static date(date?: Date): number {
    date = date || dayjs().toDate();
    return dayjs(date).date();
  }

  /**
   * Get current date for month
   *
   * @param date
   * @param month
   * @param format
   */
  static transformDateForMonth(date: Date, month: number, format = 'MMMM'): DateTransformType {
    const monthFormat = dayjs(date).month(month).format(format);
    const toDate = dayjs(date).month(month).toDate();

    return {monthFormat, date: toDate, month};
  }

  /**
   * Get current date for month
   *
   * @param date
   * @param month
   * @param format
   */
  static transformDateForCalendarYear(date: Date, months: DateTransformType[]): DateTransformType[] {
    const month = dayjs(date).month();
    const monthFormat = dayjs(date).month(month).format('MMMM');

    return months.map(item => {
      if (item.month === month) {
        return { monthFormat, date, month };
      } else {
        return item;
      }
    });
  }

  /**
   * Get months
   */
  static months(year: number, date?: Date, format = 'MMMM'): DateTransformType[] {
    date = date ? dayjs(date).year(year).toDate() : dayjs().toDate();
    year = year || dayjs().year();
    const months = [];

    let dateStart = dayjs(date).year(year).startOf('year');
    const dateEnd = dayjs(date).year(year).endOf('year');

    while (dateEnd.diff(dateStart, 'month') > 0) {
      months.push(DateTransform.transformDateForMonth(date, dateStart.month(), format));
      dateStart = dateStart.add(1, 'month');
    }

    months.push(DateTransform.transformDateForMonth(date, dateEnd.month(), format));

    return months;
  }
}
