import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResponseItem, ResponseItems } from '@app/shared/interfaces/base';
import { Complect } from '@app/shared/interfaces/complect';

@Injectable({
  providedIn: 'root'
})
export class ComplectService {
  private readonly apiUrl = environment.apiUrl;
  private readonly url: string;

  constructor(private http: HttpClient) {
    this.url = `${this.apiUrl}/packages`;
  }

  /**
   * Get all complect
   */
  getAll(filter: string = ''): Observable<Complect[]> {
    return this.http.get<ResponseItems<Complect>>(this.url + filter).pipe(
      map((res: ResponseItems<Complect>) => res.data)
    );
  }

  /**
   * Get one complect
   *
   * @param id
   */
  getOne(id: number): Observable<ResponseItem<Complect>> {
    return this.http.get<ResponseItem<Complect>>(`${this.url}/${id}`);
  }

  /**
   * Create complect
   *
   * @param body
   */
  create(body): Observable<ResponseItem<Complect>> {
    return this.http.post<ResponseItem<Complect>>(this.url, body);
  }

  /**
   * Update complect
   *
   * @param body
   */
  update(body: any): Observable<ResponseItem<Complect>> {
    return this.http.put<ResponseItem<Complect>>(`${this.url}/${body.id}`, body);
  }

  /**
   * Delete complect
   *
   * @param id
   */
  delete(id: number): Observable<any> {
    return this.http.delete(`${this.url}/${id}`);
  }
}
